import React, { useEffect } from "react";
import Typed from "typed.js";
import "./hero.css";

export const Hero = () => {
  const el: any = React.useRef();
  const typed: any = React.useRef();
  useEffect(() => {
    const options = {
      strings: ["Front-End Developer", "AWS Cloud Certified", "Hodophile"],
      loop: true,
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2000,
    };

    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
  }, []);
  return (
    <section
      id="hero"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="hero-container" data-aos="fade-in">
        <h6 className="intro code-font">Hi, my name is</h6>
        <h1 className="code-font">Biswajit Ganguly</h1>
        <p>
          <span ref={el} className="typed"></span>
        </p>
        <a href="#about" className="button-link code-font">
        <i className="bi bi-box-arrow-in-right"></i> Check out more
        </a>
      </div>
    </section>
  );
};
