import React, {  } from "react";
import "./about.css";
import cardImage from "../assets/img/me.jpg";
import birthday from "../assets/img/birthday.svg";
import city from "../assets/img/city.svg";

export const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <article className="about-desc">
          <div className="container">
            <div className="about-content">
              <div className="content">
                <div className="section-title">
                  <h2>About</h2>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="row">
                      <div className="col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up">
                        <div className="count-box alert alert-info">
                          <strong><img alt="" src={birthday} height={24} width={24} style={{marginRight:"5px"}} ></img></strong>
                          <span >29 November 1988</span>
                        </div>
                      </div>
                      <div className="col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up">
                        <div className="count-box alert alert-info">
                          <strong><i className="bi bi-mortarboard-fill" ></i></strong>
                          <span>B.Sc. ( Computer Science )</span>
                        </div>
                      </div>
                      <div className="col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up">
                        <div className="count-box alert alert-info">
                          <strong><i className="bi bi-house" ></i></strong>
                          <span>Kolkata, India</span>
                        </div>
                      </div>
                      <div className="col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up">
                        <div className="count-box alert alert-info">
                          <strong><i className="bi bi-building" ></i></strong>
                          <span>Kuala Lumpur, Malaysia</span>
                        </div>
                      </div>
                      <div className="col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up">
                        <div className="count-box alert alert-info">
                          <strong><i className="bi bi-camera" ></i></strong>
                          <span>Love Photography</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-12">
                    <div className="row">
                      <div className="col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up">
                        <div className="count-box alert alert-info">
                          <strong><i className="bi bi-github"></i></strong>
                          <a target="_blank" rel="noreferrer" href="https://github.com/biswajitnov29">biswajitnov29</a>
                        </div>
                      </div>
                      <div className="col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up">
                        <div className="count-box alert alert-info">
                          <strong><i className="bi bi-linkedin" ></i></strong>
                          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/biswajitganguly/">biswajitganguly</a>
                        </div>
                      </div>
                      <div className="col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up">
                        <div className="count-box alert alert-info">
                          <strong><i className="bi bi-skype" ></i></strong>{" "}
                          <span className="badge text-bg-primary">biswajit29nov</span>
                        </div>
                      </div>
                      <div className="col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up">
                        <div className="count-box alert alert-info">
                          <strong><i className="bi bi-discord" ></i></strong>{" "}
                          <span className="badge text-bg-primary">biswajit29nov#5936</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="about-image-container">
              <img src={cardImage} className="img-fluid" alt="" />
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};
