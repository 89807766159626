import React, { useEffect } from "react";

export const Facts = () => {

    return (
    <section id="facts" className="facts">
      <div className="container">

        <div className="section-title">
          <h2>Facts</h2>
          {/* <p>Important facts of my career</p> */}
        </div>

        <div className="row no-gutters">

          <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
            <div className="count-box">
              <i className="bi bi-emoji-heart-eyes"></i>
              <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter"></span>
              <p><strong>Happy Clients</strong> works with more than 20+ client and never missed the dealine yet.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="100">
            <div className="count-box">
              <i className="bi bi-journal-richtext"></i>
              <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter"></span>
              <p><strong>Projects</strong> works on 10+ complex project like HRMS system, Risk Regulatory tool, Insurance application etc</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="200">
            <div className="count-box">
              <i className="bi bi-bricks"></i>
              <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="1" className="purecounter"></span>
              <p><strong>Architecture</strong> build 4+ project and 1 UI libray from scratch with deciding architecture for the same.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
            <div className="count-box">
              <i className="bi bi-speedometer"></i>
              <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter"></span>
              <p><strong>Agility</strong> learned Angular, ReactJS from scratched and developed multiple project with that</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
            <div className="count-box">
              <i className="bi bi-award"></i>
              <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter"></span>
              <p><strong>Awards</strong> won agile quiz competition consecutive 2 times</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
            <div className="count-box">
              <i className="bi bi-globe"></i>
              <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter"></span>
              <p><strong>Domains</strong> worked with BFS, Insurance, Telecom domain</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
            <div className="count-box">
              <i className="bi bi-ubuntu"></i>
              <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter"></span>
              <p><strong>Operating System</strong> worked with Ubuntu, Windows, WSL etc</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
            <div className="count-box">
              <i className="bi bi-clouds"></i>
              <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter"></span>
              <p><strong>Cloud Computing</strong> worked with AWS cloud computing.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
            <div className="count-box">
              <i className="bi bi-android2"></i>
              <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter"></span>
              <p><strong>Mobile Development</strong> developed couple of apps with Android(Java), IOS(Objective C) and Xamarin(.Net).</p>
            </div>
          </div>

        </div>

      </div>
    </section>
    );
}