import React, { useEffect } from "react";
import ccp from "../assets/img/aws-certified-cloud-practitioner.png";
import babyYoda from "../assets/img/bay_yoda.jpg";
import './certificate.css';

export const Certificate = () => {
  return (
    <section id="certificate" className="certificate">
      <div className="container">
        <div className="section-title">
          <h2>Certifications/Awards</h2>
        </div>
        {/* <div className="container text-center"> */}
          <div className="certificate-flex-section" >
            {/* <div className="g-col-12 g-col-md-4 g-col-lg-4"> */}
              <div className="card">
                <img src={ccp} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">
                    AWS Certified Cloud Practitioner
                  </h5>
                  <p className="card-text">
                    Foundational understanding of AWS Cloud concepts, services,
                    and terminology.
                  </p>
                </div>
              </div>
            {/* </div> */}
            {/* <div className="g-col-12 g-col-md-4 g-col-lg-4"> */}
              <div className="card" >
                <img src={babyYoda} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">Topdanmark 2021 and 2022 Agile champ</h5>
                  <p className="card-text">
                    Consucutive winner of Agile quiz compition
                  </p>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      {/* </div> */}
    </section>
  );
};
