
import React, {  } from "react";
import "./about.css";
import wordCloud from "../assets/img/word-cloud.png";
import frontend from "../assets/img/frontend.png";
import fintech from "../assets/img/fintech.png";

export const Highlights = () => {
    return (
        <section id="highlights" className="highlights">
        <div className="container">
            <div className="section-title">
                <h2>Highlights</h2>
                <div className="row row-cols-1 row-cols-md-3 g-4 highlight-section">
                <div className="col">
                    <div className="card h-100" data-aos="flip-left">
                    <img src={wordCloud} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title">Experiences</h5>
                        <p className="card-text">
                        <ul className="key-points">
                            <li>
                            <i className="bi bi-star-fill"></i>{" "}
                            <span>11+ years of experience in FrontEnd Development</span>
                            </li>
                            <li>
                            <i className="bi bi-star-fill"></i>{" "}
                            <span>
                                End to End Project Implementation and Leading teams
                            </span>
                            </li>
                        </ul>
                        </p>
                    </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100" data-aos="flip-left">
                    <img src={frontend} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title">Technologies</h5>
                        <p className="card-text">
                        <ul className="key-points">
                            <li>
                            <i className="bi bi-star-fill"></i>{" "}
                            <span>
                                10+ years of experience in Frontend development with
                                Nodejs, HTML, CSS etc.
                            </span>
                            </li>
                            <li>
                            <i className="bi bi-star-fill"></i>{" "}
                            <span>
                                7+ years of experience with Angular, ReactJS FrontEnd
                                libraries.
                            </span>
                            </li>
                            <li>
                            <i className="bi bi-star-fill"></i>{" "}
                            <span>4+ years of experience with Java and .Net.</span>
                            </li>
                        </ul>
                        </p>
                    </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100" data-aos="flip-left">
                    <img src={fintech} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title">Domain Knowledge</h5>
                        <p className="card-text">
                        <ul className="key-points">
                            <li>
                            <i className="bi bi-star-fill"></i>{" "}
                            <span>
                                2+ years experience in BFS, Capital marketing domain.
                            </span>
                            </li>
                            <li>
                            <i className="bi bi-star-fill"></i>{" "}
                            <span>3+ years experience in Insurance domain.</span>
                            </li>
                        </ul>
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      </section>
    );
  };