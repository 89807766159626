import React, { useEffect } from "react";
import "./header.css";

export const HeaderSection = () => {
  const scrollDirection = useScrollDirection();
  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }
  return (
    <header
      className={`app-header ${scrollDirection === "down" && "hide-header"}`}
    >
      <nav className="">
        <div className="img img-fluid rounded-circle"></div>
        <div className="nav-menu-seaction d-none d-sm-none d-md-block">
          <ol>
            <li className="fadedown-enter-done">
              <a href="#highlights" className="nav-link scrollto">
                Highlights
              </a>
            </li>
            <li className="fadedown-enter-done">
              <a href="#about" className="nav-link scrollto">
                About
              </a>
            </li>
            <li className="fadedown-enter-done">
              <a href="#facts" className="nav-link scrollto">
                Facts
              </a>
            </li>
            <li className="fadedown-enter-done">
              <a href="#skills" className="nav-link scrollto">
                Skills
              </a>
            </li>
            <li className="fadedown-enter-done">
              <a href="#expirence" className="nav-link scrollto">
                Experience
              </a>
            </li>
            <li className="fadedown-enter-done">
              <a href="/#contact">Contact</a>
            </li>
          </ol>
          {/* <div className="fadedown-enter-done">
            <a
              className="resume-button"
              href="/resume.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resume
            </a>
          </div> */}
        </div>
        <div className="d-md-none d-lg-none">
          <div>
            <div className="ham-box">
              <div className="ham-box-inner">
                <i className="bi bi-list" onClick={toggleMenu}></i>
              </div>
            </div>
            <aside
              aria-hidden="true"
              tabIndex={-1}
              className={`mobile-menu ${ showMenu ? "visible" : ""}`}
            >
              <nav>
                <ol>
                  <li>
                    <a href="/#about">About</a>
                  </li>
                  <li>
                    <a href="/#jobs">Experience</a>
                  </li>
                  <li>
                    <a href="/#projects">Work</a>
                  </li>
                  <li>
                    <a href="/#contact">Contact</a>
                  </li>
                </ol>
              </nav>
            </aside>
          </div>
        </div>
      </nav>
    </header>
  );
};

// scroll direction hook
function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = React.useState<string>();

  React.useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}
