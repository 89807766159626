import React, { useEffect } from "react";
import { select } from "../utils";

const ProgressBar = (props:any) => {
  return (
    <div className="progress-bar-wrap">
      <div
        className="progress-bar"
        role="progressbar"
        aria-valuenow={props.valueNow}
        aria-valuemin={0}
        aria-valuemax={100}
      ></div>
    </div>
  );
};
export const Skills = () => {
  useEffect(() => {
    let skilsContent = select(".skills-content");
    if (skilsContent) {
      new Waypoint({
        element: skilsContent,
        offset: "80%",
        handler: function (direction) {
          let progress = select(".progress .progress-bar", true);
          progress.forEach((el: any) => {
            el.style.width = el.getAttribute("aria-valuenow") + "%";
          });
        },
      });
    }
  });
  return (
    <section id="skills" className="skills section-bg">
      <div className="container">
        <div className="section-title">
          <h2>Skills</h2>
        </div>

        <div className="row skills-content">
          <div className="col-lg-6" data-aos="fade-up">
            <div className="progress">
              <span className="skill">
                ReactJS <i className="val">80%</i>
              </span>
              <ProgressBar valueNow={80} />
            </div>

            <div className="progress">
              <span className="skill">
                JavaScript <i className="val">75%</i>
              </span>
              <ProgressBar valueNow={75} />
            </div>

            <div className="progress">
              <span className="skill">
                CSS <i className="val">70%</i>
              </span>
              <ProgressBar valueNow={70} />
            </div>

            <div className="progress">
              <span className="skill">
                JQuery <i className="val">50%</i>
              </span>
              <ProgressBar valueNow={50} />
            </div>

            <div className="progress">
              <span className="skill">
                Aws Cloud <i className="val">50%</i>
              </span>
              <ProgressBar valueNow={50} />
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <div className="progress">
              <span className="skill">
                Angular <i className="val">70%</i>
              </span>
              <ProgressBar valueNow={70} />
            </div>

            <div className="progress">
              <span className="skill">
                NodeJs <i className="val">80%</i>
              </span>
              <ProgressBar valueNow={80} />
            </div>

            <div className="progress">
              <span className="skill">
                HTML <i className="val">70%</i>
              </span>
              <ProgressBar valueNow={70} />
            </div>

            <div className="progress">
              <span className="skill">
                Bloomreach CMS <i className="val">80%</i>
              </span>
              <ProgressBar valueNow={80} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
