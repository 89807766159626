import { useEffect } from "react";
import AOS from "aos";
import "./App.css";
import { on, scrollto, select } from "./utils";
import { About } from "./components/about";
import { Hero } from "./components/hero";
import { Facts } from "./components/facts";
import { Skills } from "./components/skills";
import { Experience } from "./components/experience";
import { Contact } from "./components/contact";
import { HeaderSection } from "./components/header";
import { Highlights } from "./components/highlights";
import { Certificate } from "./components/certificate";

function App() {
  let navbarlinks = select("#navbar .scrollto", true);
  useEffect(() => {
    AOS.init();
    /**
     * Mobile nav toggle
     */
    on("click", ".mobile-nav-toggle", (e) => {
      e.stopPropagation();
      select("#root").classList.toggle("mobile-nav-active");
      let _this = select(".mobile-nav-toggle");
      _this.classList.toggle("bi-list");
      _this.classList.toggle("bi-x");
    });
    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on("click",".scrollto", (e:any) => {
        e.preventDefault();
        let root = select("#root");
        if (root.classList.contains("mobile-nav-active")) {
          root.classList.remove("mobile-nav-active");
          let navbarToggle = select(".mobile-nav-toggle");
          navbarToggle.classList.toggle("bi-list");
          navbarToggle.classList.toggle("bi-x");
        }
        scrollto(e.path[0].getAttribute('href'));
      },
      true
    );
  }, []);
  return (
    <div className="" >
      <HeaderSection />
      <Hero />
      <main id="main">
        <Highlights />
        <About />
        <Facts />
        <Skills />
        <Certificate />
        <Experience />
        <Contact />
      </main>
    </div>
  );
}

export default App;
