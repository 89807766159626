export const select = (el: any, all = false) => {
  el = el.trim();
  if (all) {
    const eleList = document.querySelectorAll(el);
    return [...Array.from(eleList)];
  } else {
    return document.querySelector(el);
  }
};

/**
 * Easy event listener function
 */
export const on = (type: any, el: any, listener: EventListener, all = false) => {
  let selectEl = select(el, all);
  if (selectEl) {
    if (all) {
      selectEl.forEach((e: any) => e.addEventListener(type, listener));
    } else {
      selectEl.addEventListener(type, listener);
    }
  }
};

/**
 * Easy on scroll event listener
 */
export const onscroll = (el: any, listener: EventListener) => {
  el.addEventListener("scroll", listener);
};


/**
   * Scrolls to an element with header offset
   */
export const scrollto = (el: any) => {
  let elementPos = select(el).offsetTop
  window.scrollTo({
    top: elementPos,
    behavior: 'smooth'
  })
}