import React, { useEffect } from "react";

export const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
        </div>

        <div className="row info" data-aos="fade-in">
          <div className="col-lg-7 d-flex align-items-stretch">

          </div>
          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Kuala Lumpur, Malaysia</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>biswajit29nov@gmail.com</p>
              </div>
              <div className="email">
                <i className="bi bi-linkedin" ></i>
                <h4>LinkedIn:</h4>
                <p><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/biswajitganguly/">biswajitganguly</a></p>
              </div>
              <div className="email">
                <i className="bi bi-github" ></i>
                <h4>Github:</h4>
                <p><a target="_blank" rel="noreferrer" href="https://github.com/biswajitnov29">biswajitnov29</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
